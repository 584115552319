/*
    This will find a key/value in an array that has an arbitrary level of nesting
    It would find 'c', 'hello' in this structure:  [ a: [ b: [ c: 'hello ] ] ]
 */
function findInNestedArray(array, key, value, allowPartialMatch) {
    var o;
    array?.some(function iter(a) {
        if (compareStrings(a[key], value, allowPartialMatch)) {
            o = a;
            return true;
        }
        return Array.isArray(a.children) && a.children.some(iter);
    });
    return o;
}

function findBestPartialPathMatchInNestedArray(array, key, value, allowPartialMatch) {
    // match route with greatest num of params
    let matches = [];

    array?.forEach(function iter(a) {
        if (compareStrings(a[key], value, allowPartialMatch)) {
            matches.push(a);
        }
        return Array.isArray(a.children) && a.children.some(iter);
    });

    matches = matches.sort((a,b) => {
        if (a?.pathname?.length < b?.pathname?.length) {
            return 1;
        } else if (a?.pathname?.length > b?.pathname?.length) {
            return -1;
        }

        return 0;
    });

    // return first item
    return matches[0];
}

function compareStrings(a, b, allowPartialMatch) {
    if (allowPartialMatch) {
        return a?.startsWith(b) || b?.startsWith(a);
    } else {
        return a === b;
    }
}

function isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype
}


export {findInNestedArray, isEmptyObject, findBestPartialPathMatchInNestedArray}
