/**
 * @typedef {{ title: string }} Product
 * @param {Product} a
 * @param {Product} b
 */
const sortAlphabeticalNoSymbol = (a, b) =>
  a.title
    .replace(/\W/g, "")
    .toLocaleLowerCase()
    .localeCompare(b.title.replace(/\W/g, "").toLocaleLowerCase());

export default sortAlphabeticalNoSymbol;
