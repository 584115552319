import React from "react";
import {
  SdfPromoBox,
  SdfCarousel,
  SdfCarouselItem,
  SdfCard,
} from "@synerg/react-components";

const MessageTasks = ({ message }) => {
  let isInsideSublist = false;
  return (
    <ul className='m-0 p-0'>
      {message.map((item, idx) => {
        if (item === "Build three integrations:") {
          isInsideSublist = true;

          return (
            <li key={idx} className='list-none'>
              {item}
              <ul className='list-disc sm:w-full'>
                {message.slice(idx + 1).map((subItem, subIdx) => {
                  if (subItem === "Build three integrations:") {
                    isInsideSublist = false;
                    return null;
                  }
                  return <li key={`${idx}-${subIdx}`}>{subItem}</li>;
                })}
              </ul>
            </li>
          );
        }
        if (isInsideSublist && item !== "Build three integrations:") {
          return null;
        }
        return (
          <li key={idx} className='list-none ml-2'>
            {item}
          </li>
        );
      })}
    </ul>
  );
};

const TimelineCarouselPartnerItem = ({ details, message, ...rest }) => {
  return (
    <div className='p-10'>
      <SdfCard className='w-11/12 h-3xl sm:h-2xl mx-auto overflow-hidden'>
        <SdfPromoBox
          variant='clear'
          align='center'
          size='sm'
          className='pt-4 pr-4 pl-4'
          {...rest}
        />
        <div className='m-0 text-body-02 whitespace-pre-wrap pb-10 pr-4 pl-4'>
          <MessageTasks message={message} />
          <hr className='my-4 bg-action-default-50' />
          {details
            .replace(/\. (?=[A-Z])/g, ".\n\n")
            .split("\n\n")
            .map((paragraph, index) => (
              <p key={index}>{paragraph.trim()}</p>
            ))}
        </div>
      </SdfCard>
    </div>
  );
};

export const TimelineCarouselPartner = ({ timelineItems }) => {
  return (
    <div className='items-center'>
      <SdfCarousel>
        {timelineItems.map((item, currentIndex) => (
          <SdfCarouselItem key={currentIndex}>
            <TimelineCarouselPartnerItem
              key={currentIndex}
              {...item}
            ></TimelineCarouselPartnerItem>
          </SdfCarouselItem>
        ))}
      </SdfCarousel>
    </div>
  );
};

export default TimelineCarouselPartner;
