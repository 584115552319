import { Link } from "react-router-dom";
export function HeroImageList({ alt, linkList, partnerLanding, ...rest }) {
  return (
    <div>
      {linkList.map((item, idx) => (
        <ul className="list-none m-4">
          <li className="text-heading-02">{item.title}</li>
          <li>
            {" "}
            <Link
              to={item.pathName}
              className='text-heading-01 no-underline whitespace-nowrap'
            >
              {item.linkText}
            </Link>
          </li>
        </ul>
      ))}
    </div>
  );
}
