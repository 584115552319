const commonApis = [
  {
    verb: "POST",
    path: "/auth/oauth/v2/token",
    name: "ADP Access Token",
    canonical: "",
    text: "Access Token",
  },
];

const startItemList = [
  {
    header: "Employee Demographic Data",
    message:
      "Read all employee demographic data and related validation tables. Sensitive personal information is hidden by default and can be allowed in the application.",
    illustrationName: "people-1",
    type: "read",
    apis: [
      ...commonApis,
      {
        verb: "DELETE",
        path: "/core/v1/event-notification-messages/{adp-msg-msgid}",
        name: "Delete the event notification message once the consumer has processed it successfully. The DELETE Acknowledges successful receipt and processing of the request.",
        canonical:
          "/core/messageCenterManagement/notificationManagement/notificationViewing/notificationEvent.delete",
        text: "Event Notification Message",
        type: "regular",
        domain: "core",
        subCategory: "Event Notification Messages",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/core/v1/event-notification-messages",
        name: "Request the event notification message that the request is authorized to view",
        canonical:
          "/core/messageCenterManagement/notificationManagement/notificationViewing/notificationEvent.read",
        text: "Read Event Notification Message",
        type: "regular",
        domain: "core",
        subCategory: "Event Notification Messages",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/business-units",
        name: "List of items in validation table - Business Units",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/businessUnits.items.read",
        text: "List of tems in the validation table - Business Units",
        type: "table",
        domain: "hcm",
        subCategory: "Business Units",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/cost-centers",
        name: "List of items in validation table - Cost Centers",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/costCenters.items.read",
        text: "List of tems in the validation table - Cost Centers",
        type: "table",
        domain: "hcm",
        subCategory: "Cost Centers",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/departments",
        name: "List of items in validation table - Departments",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/departments.items.read",
        text: "List of tems in the validation table - Departments",
        type: "table",
        domain: "hcm",
        subCategory: "Departments",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/jobs/meta",
        name: "Returns a meta for Jobs, Pass method parameter to reterive appropriate response",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/jobs.item.meta.read",
        text: "Returns the meta for the Jobs validation tables",
        type: "meta",
        domain: "hcm",
        subCategory: "Meta APIs",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/jobs/{item-id}",
        name: "Details of a specific record in the validation table - Jobs",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/jobs.item.read",
        text: "Specific record in the validation table - Jobs",
        type: "table",
        domain: "hcm",
        subCategory: "Jobs",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/meta",
        name: "Returns a meta for validation tables, Pass method and type parameters in order to reterive appropriate response",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/validationTable.meta.read",
        text: "Returns the meta for validation tables based on parameters",
        type: "meta",
        domain: "hcm",
        subCategory: "Meta APIs",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hr/v1/corporate-contacts",
        name: "Request the list of corporate directory entries.",
        canonical:
          "/hr/organizationInformationManagement/corporateDirectoryManagement/corporateDirectoryManagement/corporateDirectory.read",
        text: "Corporate Directory",
        type: "regular",
        domain: "hr",
        subCategory: "Corporate Directory",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v1/corporate-contacts/{contact-id}",
        name: "Request the details of a contact",
        canonical:
          "/hr/organizationInformationManagement/corporateDirectoryManagement/corporateDirectoryManagement/corporateDirectory.read",
        text: "Corporate Contact",
        type: "regular",
        domain: "hr",
        subCategory: "Corporate Directory",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/{sor-id}/hr/v1/corporate-contact-photos/{image-id}.{image-extension}",
        name: "Request an image of a contact",
        canonical:
          "/hr/organizationInformationManagement/corporateDirectoryManagement/corporateDirectoryManagement/corporateDirectory.read",
        text: "Corporate Contact Image",
        type: "regular",
        domain: "hr",
        subCategory: "Corporate Directory",
        globalDevPortalFlag: false,
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.work-assignment.additional-remuneration.add",
        name: "A worker's additional remuneration (e.g. bonus, commission, etc.) is added.",
        canonical:
          "/hr/workerInformationManagement/workAssignmentManagement/workAssignmentManagement/worker.workAssignment.additionalRemuneration.add",
        text: "Add Additional Remuneration",
        type: "regular",
        domain: "hr",
        subCategory: "Add Additional Remuneration",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.work-assignment.additional-remuneration.add/meta",
        name: "Returns an event metadata",
        canonical:
          "/hr/workerInformationManagement/workAssignmentManagement/workAssignmentManagement/worker.workAssignment.additionalRemuneration.add",
        text: "Add Additional Remuneration Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Add Additional Remuneration",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v3/worker-management/{code-type}/WFN/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in WFN",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v4/worker-management/{code-type}/wfn/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in WFN",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v3/worker-management/{code-type}/esi/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in ESI",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v4/worker-management/{code-type}/nas-hcm/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support HR processing",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g cdf-variables, cdf-values",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}/worker-images/photo",
        name: "Returns a worker profile picture",
        canonical:
          "/hr/workerInformationManagement/workerManagement/imageManagement/worker.photo.read",
        text: "Worker Profile Picture",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Images",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics",
        name: "Request the list of all available worker demographics that the requester is authorized to view.",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographics",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics/{aoid}",
        name: "Returns a worker demographic",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographic",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics/meta",
        name: "Returns a meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographic Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
    ],
  },
  {
    header: "Time & Attendance",
    message: "Read and update timecards and work schedules.",
    illustrationName: "time-clock",
    type: "read",
    apis: [
      ...commonApis,
      {
        verb: "DELETE",
        path: "/core/v1/event-notification-messages/{adp-msg-msgid}",
        name: "Delete the event notification message once the consumer has processed it successfully. The DELETE Acknowledges successful receipt and processing of the request.",
        canonical:
          "/core/messageCenterManagement/notificationManagement/notificationViewing/notificationEvent.delete",
        text: "Event Notification Message",
        type: "regular",
        domain: "core",
        subCategory: "Event Notification Messages",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/core/v1/event-notification-messages",
        name: "Request the event notification message that the request is authorized to view",
        canonical:
          "/core/messageCenterManagement/notificationManagement/notificationViewing/notificationEvent.read",
        text: "Read Event Notification Message",
        type: "regular",
        domain: "core",
        subCategory: "Event Notification Messages",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/cost-centers",
        name: "List of items in validation table - Cost Centers",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/costCenters.items.read",
        text: "List of tems in the validation table - Cost Centers",
        type: "table",
        domain: "hcm",
        subCategory: "Cost Centers",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/departments",
        name: "List of items in validation table - Departments",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/departments.items.read",
        text: "List of tems in the validation table - Departments",
        type: "table",
        domain: "hcm",
        subCategory: "Departments",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/jobs/meta",
        name: "Returns a meta for Jobs, Pass method parameter to reterive appropriate response",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/jobs.item.meta.read",
        text: "Returns the meta for the Jobs validation tables",
        type: "meta",
        domain: "hcm",
        subCategory: "Meta APIs",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/jobs/{item-id}",
        name: "Details of a specific record in the validation table - Jobs",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/jobs.item.read",
        text: "Specific record in the validation table - Jobs",
        type: "table",
        domain: "hcm",
        subCategory: "Jobs",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hcm/v1/validation-tables/meta",
        name: "Returns a meta for validation tables, Pass method and type parameters in order to reterive appropriate response",
        canonical:
          "/hcm/standardsInformationManagement/clientMasterDataManagement/validationTableManagement/validationTable.meta.read",
        text: "Returns the meta for validation tables based on parameters",
        type: "meta",
        domain: "hcm",
        subCategory: "Meta APIs",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v3/worker-management/{code-type}/WFN/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in WFN",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v4/worker-management/{code-type}/wfn/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in WFN",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v3/worker-management/{code-type}/esi/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in ESI",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v4/worker-management/{code-type}/nas-hcm/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support HR processing",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g cdf-variables, cdf-values",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.read",
        name: "A worker reads.",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.read",
        text: "Read Worker",
        type: "regular",
        domain: "hr",
        subCategory: "Read Worker",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.read/meta",
        name: "Returns an event metadata",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.read",
        text: "Read Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Read Worker",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers",
        name: "Request the list of all available workers that the requester is authorized to view",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Workers Data Collection",
        type: "regular",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}",
        name: "Returns a worker",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Individual Worker Data",
        type: "regular",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/meta",
        name: "Returns a meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}/meta",
        name: "Returns a worker specific meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Specific Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/staticContent/{sor-id}/hr/associates/{aoid}/photos/{photo-id}",
        name: "Request a photo",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Worker Photo (Legacy)",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Images (Deprecated)",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics",
        name: "Request the list of all available worker demographics that the requester is authorized to view.",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographics",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics/{aoid}",
        name: "Returns a worker demographic",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographic",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics/meta",
        name: "Returns a meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographic Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/time/v2/workers/{aoid}/team-time-cards",
        name: "Get a worker's team's timecards.  That is all the time cards for the worker's team members.  The worker (team lead / manager) is identified by workers/{aoid}",
        canonical:
          "/time/timeLaborManagement/timeEntryManagement/timeSheetManagement/timeSheet.read",
        text: "Team Time Cards",
        type: "regular",
        domain: "time",
        subCategory: "Team Time Cards",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/time/v2/workers/{aoid}/team-time-cards/meta",
        name: "Returns API metadata. NOTE: The {aoid} URI parameter refers to the team lead / manager, whose team data is being requested.",
        canonical:
          "/time/timeLaborManagement/timeEntryManagement/timeSheetManagement/timeSheet.read",
        text: "Team Time Cards Meta",
        type: "meta",
        domain: "time",
        subCategory: "Team Time Cards",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/time/v2/workers/{aoid}/time-cards",
        name: "Get a workers time card for a given period.  Filter criteria is required.",
        canonical:
          "/time/timeLaborManagement/timeEntryManagement/timeSheetManagement/timeSheet.read",
        text: "Time Cards",
        type: "regular",
        domain: "time",
        subCategory: "Time Cards",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/time/v2/workers/{aoid}/time-cards/{time-card-id}",
        name: "Get a workers time card for a given period.  Filter criteria is required.",
        canonical:
          "/time/timeLaborManagement/timeEntryManagement/timeSheetManagement/timeSheet.read",
        text: "Time Card",
        type: "regular",
        domain: "time",
        subCategory: "Time Cards",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/time/v2/workers/{aoid}/time-cards/meta",
        name: "Returns API metadata",
        canonical:
          "/time/timeLaborManagement/timeEntryManagement/timeSheetManagement/timeSheet.read",
        text: "Time Cards Meta",
        type: "meta",
        domain: "time",
        subCategory: "Time Cards",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/time/v1/workers/{aoid}/time-entry-details",
        name: "Request a time entry configuration",
        canonical:
          "/time/timeLaborManagement/timeEntryManagement/timeSheetManagement/timeSheet.read",
        text: "Time Entry Configuration",
        type: "regular",
        domain: "time",
        subCategory: "Time Entry Details",
        globalDevPortalFlag: false,
      },
    ],
  },
  {
    header: "New Hire Onboarding",
    message:
      "Send new hires, onboard completed hires, and read and rehire existing employees to ADP.",
    illustrationName: "teacher",
    type: "write",
    apis: [
      ...commonApis,
      {
        verb: "DELETE",
        path: "/core/v1/event-notification-messages/{adp-msg-msgid}",
        name: "Delete the event notification message once the consumer has processed it successfully. The DELETE Acknowledges successful receipt and processing of the request.",
        canonical:
          "/core/messageCenterManagement/notificationManagement/notificationViewing/notificationEvent.delete",
        text: "Event Notification Message",
        type: "regular",
        domain: "core",
        subCategory: "Event Notification Messages",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/core/v1/event-notification-messages",
        name: "Request the event notification message that the request is authorized to view",
        canonical:
          "/core/messageCenterManagement/notificationManagement/notificationViewing/notificationEvent.read",
        text: "Read Event Notification Message",
        type: "regular",
        domain: "core",
        subCategory: "Event Notification Messages",
        globalDevPortalFlag: true,
      },
      {
        verb: "POST",
        path: "/hcm/v2/applicant.onboard",
        name: "Data related to the applicant new hire onboarding request. A new hire is an applicant who has not previously worked for the organization.",
        canonical:
          "/hcm/integrationManagement/applicantOnboardingManagement/onboardingProcessManagement/applicant.onboard",
        text: "Initiate Applicant Onboarding (New Hire Applicants)",
        type: "regular",
        domain: "hcm",
        subCategory: "New Hire Onboarding Process Management",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hcm/v2/applicant.onboard/meta",
        name: "Returns metadata for the applicant new hire onboarding API",
        canonical:
          "/hcm/integrationManagement/applicantOnboardingManagement/onboardingProcessManagement/applicant.onboard.meta.read",
        text: "Metadata - Initiate Applicant Onboarding API (New Hire Applicants)",
        type: "meta",
        domain: "hcm",
        subCategory: "New Hire Onboarding API Metadata",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v1/corporate-contacts",
        name: "Request the list of corporate directory entries.",
        canonical:
          "/hr/organizationInformationManagement/corporateDirectoryManagement/corporateDirectoryManagement/corporateDirectory.read",
        text: "Corporate Directory",
        type: "regular",
        domain: "hr",
        subCategory: "Corporate Directory",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v1/corporate-contacts/{contact-id}",
        name: "Request the details of a contact",
        canonical:
          "/hr/organizationInformationManagement/corporateDirectoryManagement/corporateDirectoryManagement/corporateDirectory.read",
        text: "Corporate Contact",
        type: "regular",
        domain: "hr",
        subCategory: "Corporate Directory",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/{sor-id}/hr/v1/corporate-contact-photos/{image-id}.{image-extension}",
        name: "Request an image of a contact",
        canonical:
          "/hr/organizationInformationManagement/corporateDirectoryManagement/corporateDirectoryManagement/corporateDirectory.read",
        text: "Corporate Contact Image",
        type: "regular",
        domain: "hr",
        subCategory: "Corporate Directory",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v3/worker-management/{code-type}/WFN/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in WFN",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v4/worker-management/{code-type}/wfn/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in WFN",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v3/worker-management/{code-type}/esi/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in ESI",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v4/worker-management/{code-type}/nas-hcm/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support HR processing",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g cdf-variables, cdf-values",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}/worker-images/photo",
        name: "Returns a worker profile picture",
        canonical:
          "/hr/workerInformationManagement/workerManagement/imageManagement/worker.photo.read",
        text: "Worker Profile Picture",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Images",
        globalDevPortalFlag: true,
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.read",
        name: "A worker reads.",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.read",
        text: "Read Worker",
        type: "regular",
        domain: "hr",
        subCategory: "Read Worker",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.read/meta",
        name: "Returns an event metadata",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.read",
        text: "Read Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Read Worker",
        globalDevPortalFlag: true,
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.rehire",
        name: "A worker is rehired.",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.rehire",
        text: "Rehire Worker",
        type: "regular",
        domain: "hr",
        subCategory: "Rehire Worker",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.rehire/meta",
        name: "Returns an event metadata",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.rehire",
        text: "Rehire Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Rehire Worker",
        globalDevPortalFlag: true,
      },
      {
        verb: "POST",
        path: "/hr/v3/lifecycle-management/workers/{aoid}/rehire",
        name: "Perform the rehire action for a given worker",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.rehire",
        text: "Rehire Worker",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Rehire",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers",
        name: "Request the list of all available workers that the requester is authorized to view",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Workers Data Collection",
        type: "regular",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}",
        name: "Returns a worker",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Individual Worker Data",
        type: "regular",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/meta",
        name: "Returns a meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}/meta",
        name: "Returns a worker specific meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Specific Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/staticContent/{sor-id}/hr/associates/{aoid}/photos/{photo-id}",
        name: "Request a photo",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Worker Photo (Legacy)",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Images (Deprecated)",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics",
        name: "Request the list of all available worker demographics that the requester is authorized to view.",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographics",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics/{aoid}",
        name: "Returns a worker demographic",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographic",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/worker-demographics/meta",
        name: "Returns a meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/workerDemographic.read",
        text: "Worker Demographic Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Worker Demographics",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v4/payroll-instruction-sets/{code-type}/enterprise",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction set management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g address, earnings, deductions, pay-rate, paymentType, paymentReason",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Instruction Codelist",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-instruction-management/{instruction-code-type}/run",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Deprecated Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-instruction-management/{instruction-code-type}/run/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Deprecated Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-instruction-management/{instruction-code-type}/run",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-instruction-management/{instruction-code-type}/run/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-instruction-management/{code-type}/WFN/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the payroll instruction management in WFN",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelists",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v4/payroll-instruction-management/{code-type}/wfn/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the payroll instruction management in WFN",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelists",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v4/payroll-instruction-sets/{code-type}/enterprise",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction set management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g address, earnings, deductions, pay-rate, paymentType, paymentReason",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Instruction Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/core/v4/state-county-codes/{code-type}/enterprise/{version-id}",
        name: "Request the list of all available client-configured county codes for all U.S. states (e.g. MD, IN)",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve Client-Configured County Codes for all U.S. States",
        type: "codelist",
        domain: "payroll",
        subCategory: "State County Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/core/v4/state-county-codes/{code-type}/enterpriseportal/{version-id}",
        name: "Request the list of all available client-configured county codes for all U.S. states (e.g. MD, IN)",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve Client-Configured County Codes for all U.S. States",
        type: "codelist",
        domain: "payroll",
        subCategory: "State County Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/core/v4/state-county-codes/{code-type}/vantage/{version-id}",
        name: "Request the list of all available client-configured county codes for all U.S. states (e.g. MD, IN)",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve Client-Configured County Codes for all U.S. States",
        type: "codelist",
        domain: "payroll",
        subCategory: "State County Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "POST",
        path: "/events/payroll/v1/worker.pay-distribution.change",
        name: "A worker changes how the pay is distributed for a given payroll agreement",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/payDistributionManagement/worker.payDistribution.change",
        text: "Change Pay Distribution",
        type: "regular",
        domain: "payroll",
        subCategory: "Change Pay Distribution",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/events/payroll/v1/worker.pay-distribution.change/meta",
        name: "Returns an event metadata",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/payDistributionManagement/worker.payDistribution.change",
        text: "Change Pay Distribution Meta",
        type: "meta",
        domain: "payroll",
        subCategory: "Change Pay Distribution",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/payroll/v2/workers/{aoid}/pay-distributions",
        name: "Request all available pay distributions for a worker",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/payDistributionManagement/worker.payDistribution.read",
        text: "Pay Distributions",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Distributions",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/payroll/v2/workers/{aoid}/pay-distributions/{pay-distribution-id}",
        name: "Returns a pay distribution",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/payDistributionManagement/worker.payDistribution.read",
        text: "Pay Distribution",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Distributions",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/payroll/v2/workers/{aoid}/pay-distributions/meta",
        name: "Returns a meta",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/payDistributionManagement/worker.payDistribution.read",
        text: "Pay Distribution Meta",
        type: "meta",
        domain: "payroll",
        subCategory: "Pay Distributions",
        globalDevPortalFlag: true,
      },
    ],
  },
  {
    header: "Payroll Input",
    message:
      "Add pay-data batches for a payroll cycle to include earnings, deductions, reimbursements and more.",
    illustrationName: "money-hand",
    type: "write",
    apis: [
      ...commonApis,
      {
        verb: "DELETE",
        path: "/core/v1/event-notification-messages/{adp-msg-msgid}",
        name: "Delete the event notification message once the consumer has processed it successfully. The DELETE Acknowledges successful receipt and processing of the request.",
        canonical:
          "/core/messageCenterManagement/notificationManagement/notificationViewing/notificationEvent.delete",
        text: "Event Notification Message",
        type: "regular",
        domain: "core",
        subCategory: "Event Notification Messages",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/core/v1/event-notification-messages",
        name: "Request the event notification message that the request is authorized to view",
        canonical:
          "/core/messageCenterManagement/notificationManagement/notificationViewing/notificationEvent.read",
        text: "Read Event Notification Message",
        type: "regular",
        domain: "core",
        subCategory: "Event Notification Messages",
        globalDevPortalFlag: true,
      },
      {
        verb: "POST",
        path: "worker.legalName.change.eventNotify",
        name: "Communicates the occurrence of an event",
        canonical:
          "/hr/workerInformationManagement/workerManagement/associateManagement/worker.legalName.change.eventNotify.subscribe",
        text: "Change Legal Name Notification",
        type: "regular",
        domain: "hr",
        subCategory: "Change Legal Name",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v3/worker-management/{code-type}/WFN/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in WFN",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v4/worker-management/{code-type}/wfn/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in WFN",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v3/worker-management/{code-type}/esi/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the worker management in ESI",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Worker Management Codelists",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/hr/v4/worker-management/{code-type}/nas-hcm/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support HR processing",
        canonical:
          "/hr/workerInformationManagement/workerManagement/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g cdf-variables, cdf-values",
        type: "codelist",
        domain: "hr",
        subCategory: "Worker Management Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "POST",
        path: "/events/hr/v1/worker.read",
        name: "A worker reads.",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.read",
        text: "Read Worker",
        type: "regular",
        domain: "hr",
        subCategory: "Read Worker",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/events/hr/v1/worker.read/meta",
        name: "Returns an event metadata",
        canonical:
          "/hr/workerInformationManagement/workerManagement/lifecycleManagement/worker.read",
        text: "Read Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Read Worker",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers",
        name: "Request the list of all available workers that the requester is authorized to view",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Workers Data Collection",
        type: "regular",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}",
        name: "Returns a worker",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Individual Worker Data",
        type: "regular",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/meta",
        name: "Returns a meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/hr/v2/workers/{aoid}/meta",
        name: "Returns a worker specific meta",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Specific Worker Meta",
        type: "meta",
        domain: "hr",
        subCategory: "Workers Data Retrieval",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/staticContent/{sor-id}/hr/associates/{aoid}/photos/{photo-id}",
        name: "Request a photo",
        canonical:
          "/hr/workerInformationManagement/workerManagement/workerProfileManagement/worker.read",
        text: "Worker Photo (Legacy)",
        type: "regular",
        domain: "hr",
        subCategory: "Worker Images (Deprecated)",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/output-schedule-method-type-codes/autopay",
        name: "Request the list of all method type codes that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Tax Filing Statuses Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Output Delivery Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/output-schedule-process-frequency-codes/autopay",
        name: "Request the list of all process frequency codes that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Retrieve Process Frequency Codes",
        type: "codelist",
        domain: "payroll",
        subCategory: "Output Delivery Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/output-schedule-bag-type-codes/autopay",
        name: "Request the list of all bag type codes that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Payroll Processing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Output Delivery Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/output-schedule-special-handling-codes/autopay",
        name: "Request the list of all special handling codes that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Staffing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Output Delivery Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/output-schedule-trip-center-codes/autopay",
        name: "Request the list of all trip center codes that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Retrieve Process Frequency Codes",
        type: "codelist",
        domain: "payroll",
        subCategory: "Output Delivery Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/output-schedule-output-type-codes/autopay",
        name: "Request the list of all output type codes that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Payroll Processing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Output Delivery Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/output-schedule-output-frequency-codes/autopay",
        name: "Request the list of all output frequency codes that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Staffing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Output Delivery Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/output-maintenance-quarters/autopay",
        name: "Request the list of all a company's maintenance quarter codes that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Payroll Processing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Output Delivery Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/payroll-processing-error-codes/autopay",
        name: "Request the list of all payroll errors and descriptions that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Payroll Processing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Processing Jobs Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/cafeteria-plan-tax-options/autopay",
        name: "Request the list of all available Cafeteria Plan Tax Options that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Cafeteria Plan Tax Options",
        type: "codelist",
        domain: "payroll",
        subCategory: "Cafeteria Plan Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-configuration-management/cafeteria-plan-benefit-report-type-codes/autopay",
        name: "Request the list of all available Cafeteria Plan Benefit Verification Report Options that the requester is authorized to view",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Cafeteria Plan Benefit Verification Report Options.",
        type: "codelist",
        domain: "payroll",
        subCategory: "Cafeteria Plan Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-configuration/{code-type}/autopay",
        name: "Request list of all available companies which has active block print automation indicator",
        canonical:
          "/payroll/payrollManagement/payrollConfigurationManagement/codeListViewing/codelist.read",
        text: "Retrieve list of active block print automation companies",
        type: "codelist",
        domain: "payroll",
        subCategory: "Pay Statement Attribute Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v4/payroll-configurations/{code-type}/nas-hcm/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll processing",
        canonical:
          "/payroll/payrollManagement/payrollConfigurations/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g address, earnings, deductions, pay-rate, paymentType, paymentReason",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Configuration Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v4/payroll-instruction-sets/{code-type}/enterprise",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction set management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g address, earnings, deductions, pay-rate, paymentType, paymentReason",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Instruction Codelist",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-instruction-management/{instruction-code-type}/run",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Deprecated Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-instruction-management/{instruction-code-type}/run/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Deprecated Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-instruction-management/{instruction-code-type}/run",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-instruction-management/{instruction-code-type}/run/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-instruction-management/{code-type}/WFN/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the payroll instruction management in WFN",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelists",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v4/payroll-instruction-management/{code-type}/wfn/{version-id}",
        name: "Request the list of all available codes based on the {code-type} value that are possible duirng the payroll instruction management in WFN",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Payroll Instruction Management Codelists",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v4/payroll-instruction-sets/{code-type}/enterprise",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll instruction set management",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve codes available for specific code types; for e.g address, earnings, deductions, pay-rate, paymentType, paymentReason",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Instruction Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/core/v4/state-county-codes/{code-type}/enterprise/{version-id}",
        name: "Request the list of all available client-configured county codes for all U.S. states (e.g. MD, IN)",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve Client-Configured County Codes for all U.S. States",
        type: "codelist",
        domain: "payroll",
        subCategory: "State County Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/core/v4/state-county-codes/{code-type}/enterpriseportal/{version-id}",
        name: "Request the list of all available client-configured county codes for all U.S. states (e.g. MD, IN)",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve Client-Configured County Codes for all U.S. States",
        type: "codelist",
        domain: "payroll",
        subCategory: "State County Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/core/v4/state-county-codes/{code-type}/vantage/{version-id}",
        name: "Request the list of all available client-configured county codes for all U.S. states (e.g. MD, IN)",
        canonical:
          "/payroll/payrollManagement/payrollInstructionManagement/codeListViewing/codelist.read",
        text: "Retrieve Client-Configured County Codes for all U.S. States",
        type: "codelist",
        domain: "payroll",
        subCategory: "State County Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-processing/{processing-code-type}/run",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll processing",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/codeListViewing/codelist.read",
        text: "Payroll Processing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Deprecated Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v1/payroll-processing/{processing-code-type}/run/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll processing",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/codeListViewing/codelist.read",
        text: "Payroll Processing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Deprecated Codelists",
        globalDevPortalFlag: false,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-processing/{processing-code-type}/run",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll processing",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/codeListViewing/codelist.read",
        text: "Payroll Processing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/codelists/payroll/v3/payroll-processing/{processing-code-type}/run/{version-id}",
        name: "Request the list of all available codelists of a type identified by the {code-type}, that support payroll processing",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/codeListViewing/codelist.read",
        text: "Payroll Processing Codelist",
        type: "codelist",
        domain: "payroll",
        subCategory: "Payroll Codelists",
        globalDevPortalFlag: true,
      },
      {
        verb: "POST",
        path: "/events/payroll/v1/pay-data-input.modify",
        name: "Modify pay data input information",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.modify",
        text: "Modify Pay Data Input",
        type: "regular",
        domain: "payroll",
        subCategory: "Pay Data Input Modify",
        globalDevPortalFlag: true,
      },
      {
        verb: "GET",
        path: "/events/payroll/v1/pay-data-input.modify/meta",
        name: "Returns an event metadata",
        canonical:
          "/payroll/payrollManagement/payrollProcessing/payDataInputManagement/pay-data-input.modify",
        text: "Modify Pay Data Input Meta",
        type: "meta",
        domain: "payroll",
        subCategory: "Pay Data Input Modify",
        globalDevPortalFlag: true,
      },
    ],
  },
];

const needItemList = [
  {
    header: "Instant access to APIs",
    message:
      "API Central can be purchased online and digitally activated in minutes, giving instant access to your workforce data via ADP APIs.",
    illustrationName: "ext-mdi-assured-workload",
  },
  {
    header: "ADP API library",
    message:
      "Access ADP APIs across all supported ADP platforms, covering common use cases for custom integration.",
    illustrationName: "toolbox",
  },
  {
    header: "API use case templates",
    message:
      "Jumpstart your integration projects. Select a use case for your project and it will immediately populate with relevant APIs.",
    illustrationName: "ext-mdi-dashboard-customize",
  },
  {
    header: "API discovery tool",
    message:
      "Instantly add new APIs to any ADP API Central integration project. Once you add a new API, code samples are immediately available.",
    illustrationName: "action-search",
  },
  {
    header: "Developer resources",
    message:
      "Access documentation, how-to guides, code collection samples, developer libraries and more.",
    illustrationName: "ext-mdi-data-object",
  },
  {
    header: "Data access credentials",
    message:
      "Quickly create integration projects and security certificates and add development team members (internal or external).",
    illustrationName: "media-file",
  },
  {
    header: "Ticketed support",
    message:
      "Responsive experts monitor our ticketed support channel to help keep your project on track.",
    illustrationName: "action-chat-person",
  },
  {
    header: "Data security",
    message:
      "API Central leverages OpenID Connect and OAuth 2.0 to ensure only authorized users and systems can access your data.",
    illustrationName: "ext-mdi-laptop",
  },
];

export { startItemList, needItemList };
