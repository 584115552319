import { useContext, useEffect, useRef } from "react";
import { SdfButton } from "@synerg/react-components";

import { TopButtonContext } from "../shared/TopButton";

const buyUrl = "https://apps.adp.com/en-US/apps/410612?cro=t2435";
const learnUrl = "https://apps.adp.com/en-US/apps/419897?cro=t2435";

const BuyNow = () => {
  const { setOffsetHeight } = useContext(TopButtonContext);
  const buyNowRef = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      setOffsetHeight(entry.contentRect.height);
    });

    if (buyNowRef.current) {
      resizeObserver.observe(buyNowRef.current);
    }

    return () => {
      if (buyNowRef.current) {
        resizeObserver.unobserve(buyNowRef.current);
        setOffsetHeight(0);
      }
    };
  }, [buyNowRef.current]);

  const buyProps = {
    className: "mx-auto",
    emphasis: "primary",
    icon: "external-link",
    onClick: () => window.open(buyUrl, "_blank").focus(),
  };

  const learnProps = {
    emphasis: "tertiary",
    icon: "external-link",
    onClick: () => window.open(learnUrl, "_blank").focus(),
  };

  return (
    <div
      className="fixed bottom-0 left-0 right-0 bg-action-default-100 z-50"
      ref={buyNowRef}
    >
      <div className="px-8 py-4">
        <div className="relative flex flex-col lg:flex-row gap-4 lg:gap-8">
          <div className="flex-grow">
            <div className="lg:max-w-7/8">
              <h1 className="m-0 text-heading-05 text-center lg:text-left">
                Buy API Central for your development team
              </h1>
              <p className="hidden lg:block m-0 text-body-02">
                ADP API Central is available on ADP Marketplace. Click{" "}
                <span className="font-bold">Get API Central</span> to view the
                application listing and share with your ADP system administrator
                to purchase.
              </p>
            </div>
          </div>
          <div className="flex-shrink-0 flex flex-col">
            <SdfButton {...buyProps}>
              Get API Central for ADP Workforce Now
            </SdfButton>
            <SdfButton {...learnProps}>
              Learn more about API Central for other ADP platforms
            </SdfButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyNow;
