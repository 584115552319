import { SdfFocusPane, SdfButton, SdfBadge } from "@synerg/react-components";
import Contextualize from "shared-one-ux-components/dist/contextualize/Contextualize";

import "./AssociatedApis.css";

const transformApi = ({
  uri,
  httpMethod,
  description,
  canonicalUri,
  ...rest
}) => ({
  verb: httpMethod?.toUpperCase(),
  path: uri,
  name: description,
  canonical: canonicalUri,
  ...rest,
});

const AssociatedApis = ({ apiData, closeModal }) => {
  const { header, type, apis, ...rest } = apiData || {};

  const focusPaneProps = {
    className: "associated-apis",
    heading: "Associated APIs",
    closeable: true,
    dismissOnClickOutside: true,
    hideFooter: true,
    onSdfDismiss: closeModal,
    visible: !!apiData,
    size: "xl",
  };

  const contextualizeProps = {
    projectDetail: {},
    projectType: "api_central",
    hideHeader: true,
    events: [],
    setSelectedSegment: () => null,
    filteredApis: apis?.map(transformApi),
    ...rest,
  };

  const closeButtonProps = {
    onClick: closeModal,
  };

  return (
    <SdfFocusPane {...focusPaneProps}>
      <div className="h-full flex flex-col">
        <div className="px-4 pb-4">
          <div className="m-0 text-heading-01">Use case template</div>
          <div className="flex gap-4">
            <div className="m-0 text-heading-05">{header}</div>
            <SdfBadge
              status={type === "read" ? "in-progress" : "changed"}
              spacing="tight"
            >
              {type === "read" ? "Read only" : "Read/Write"}
            </SdfBadge>
          </div>
        </div>
        <div className="px-8 overflow-y-scroll flex-grow">
          {apiData && <Contextualize {...contextualizeProps} />}
        </div>
        <div className="p-4 ml-auto mr-0">
          <SdfButton {...closeButtonProps}>Close</SdfButton>
        </div>
      </div>
    </SdfFocusPane>
  );
};

export default AssociatedApis;
