import { Link, useNavigate } from "react-router-dom";
import { SdfButton } from "@synerg/react-components";

import "./AvailableGuides.css";

/**
 * @param {{
 *  header: string,
 *  message: string,
 *  pathName: string,
 * }} props
 */
const GuideBox = ({ header, message, pathName }) => (
  <div className="flex flex-col col-span-6 md:col-span-3 xl:col-span-2 p-4 xl:p-8">
    <div className="m-0 text-heading-05">{header}</div>
    <div className="flex-grow m-0 py-4 text-body-02">{message}</div>
    <Link to={pathName} className="text-body-02 no-underline whitespace-nowrap">
      View guides
    </Link>
  </div>
);

const AvailableGuides = ({guideItemList}) => {
  const navigate = useNavigate();

  const buttonProps = {
    onClick: () => navigate("/guides/api-guides"),
    emphasis: "primary",
  };

  return (
    <div className="relative w-11/12 md:w-4/5">
      <div className="flex flex-col items-center gap-4 pb-24">
        <h2 className="m-0 text-heading-06">
          Available ADP API guides by data type
        </h2>
        <div className="w-full pt-4 pb-8 grid grid-cols-6 auto-rows-fr gap-4">
          {guideItemList.map((item, index) => (
            <GuideBox key={`guide-box-${index}`} {...item} />
          ))}
        </div>
        <SdfButton {...buttonProps}>
          <div className="px-8">View all product guides for North America</div>
        </SdfButton>
      </div>
      <div className="absolute inline-block w-xl h-xl bg-action-primary-50 available-guide-shape" />
    </div>
  );
};

export default AvailableGuides;
