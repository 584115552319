import { BrowserRouter as Router } from "react-router-dom";
import { SfcShell } from "@synerg/react-components/dist/sfc-components";
import { SdfPageLayout } from "@synerg/react-components";
import TopNav from "../topNav";
import Routes from "../../routes";
import AppBar from "../AppBar";
import ScrollToTop from "../../utility/ScrollToTop";
import TopButton from "../shared/TopButton";

export default function OneuxLayout() {
  return (
    <Router basename={`${process.env.REACT_APP_ROOT_PATH}`}>
      <ScrollToTop />
      <SfcShell>
        <AppBar />
        <TopNav />
        <SdfPageLayout className="min-h-screen">
          <TopButton>
            <Routes />
          </TopButton>
        </SdfPageLayout>
        {/* <Footer/> */}
      </SfcShell>
    </Router>
  );
}
