import React, { useState } from "react";
import { SdfButton, SdfSelectSimple } from "@synerg/react-components";
import { PageTitle } from "../PageTitle";
import { InputWidthEnum } from "../../utility/enums/InputWidthEnum";
import logo from "../../assets/images/Purple-corporate-graphic.svg";
import { useNavigate } from "react-router-dom";

export function HeroHeader({ integrationOptions }) {
  const [selectedValue, setSelectedValue] = useState();
  const navigate = useNavigate();

  const getSelectedValue = (event) => {
    setSelectedValue(event.target.value.value);
  };
  
  const handleClick = () => {
    selectedValue === "0"
      ? navigate("/getting-started/client-integration-overview")
      : navigate("/getting-started/partner-integration-overview");
  };

  return (
    <div className='flex items-center bg-action-default-50 pl-4 md:pl-5 lg:pl-5 pr-8 h-auto pt-10 pb-8 m-0'>
      <main className='m-auto items-center flex flex-col min-w-4/5'>
        <section className="text-center">
          <div>
            <PageTitle
              className='font-black text-heading-08'
              noMargin
              id='heroHeader'
            >
              Integrate with ADP’s API Platform
            </PageTitle>
          </div>
          <div
            className='pt-3 text-center sm:text-heading-04'
            id='heroDescription'
          >
            Let us personalize your developer experience by answering the
            question below
          </div>
          <div className='text-center relative'>
            <div className='logo--container'>
              <img
                src={logo}
                alt='Not found'
                className='object-contain w-full'
              />
            </div>
            <div className='flex flex-col md:flex-row lg:flex-row xl:flex-row h-full w-4/5 mx-auto items-center mt-12 gap-4'>
              <SdfSelectSimple
                id='demo-component'
                items={integrationOptions.map((options) => {
                  return { label: options.label, value: options.value };
                })}
                inputWidth_md={InputWidthEnum.FULL}
                label=''
                portal-enabled
                required-state='default'
                placeholder='How do you want to use ADP APIs?'
                className='w-3/4 cursor-pointer'
                onSdfChange={(event) => getSelectedValue(event)}
              ></SdfSelectSimple>
              <SdfButton
                data-cvs-id='3'
                id='demo-component'
                action='standard'
                emphasis='primary'
                size='sm'
                variant='contained'
                icon='nav-move-next'
                icon-placement='after'
                className="w-3/4 md:w-1/3 lg:w-1/3 xl:w-1/3"
                onClick={() => handleClick()}
              >
                Let's go
              </SdfButton>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
