import OneuxLayout from "./components/oneux-layout";
import { SdfBusyIndicator } from "@synerg/react-components";
import useLoadingEventListener from "./hooks/useEventListener";
import { useFetchProductsQuery, useFetchElasticSearchQuery } from "./store";
import { buildQueryObj } from "./utility/FetchingUtils";

function App() {
  const isThemeLoading = useLoadingEventListener(
    "sdfLoaded",
    !window.SynergThemes
  );

  const { isLoading: isProductLoading } = useFetchProductsQuery();
  const { isLoading: isElasticLoading } = useFetchElasticSearchQuery(
    buildQueryObj({ index: "usecases" })
  );

  const isLoading = isProductLoading || isThemeLoading || isElasticLoading;

  if (window.location.pathname.includes("/articles/preview/")) {
    return <OneuxLayout />;
  }
  if (window.location.pathname.includes("/usecases-preview/")) {
    return <OneuxLayout />;
  }

  return isLoading ? (
    <div className="app-load">
      <SdfBusyIndicator
        style={{ color: "#1a8099" }}
        size="xl"
        fullScreen={true}
      ></SdfBusyIndicator>
    </div>
  ) : (
    <OneuxLayout />
  );
}

export default App;
