import React from 'react';
import {SdfLink} from "@synerg/react-components";
import Icon from "../assets/images/404.svg";

export function NotFound() {
    return (
        <div className="flex flex-wrap md:flex-nowrap min-h-screen mx-4 my-4 md:mx-56 md:my-20">
            <div className="mt-2">
                <div className="text-4xl md:text-5xl font-black mb-5">
                    Page not found
                </div>
                <div className="mb-12 text-xl md:w-11/12">
                    We apologize but the page you are looking for does not exist. It has either been removed, the name
                    has changed or is temporarily unavailable. Please double-check the URL, and try again!
                </div>
                <div className="flex flex-col md:flex-row">
                    <SdfLink
                        target='_blank'
                        className="pr-3"
                        emphasis="primary"
                        href={`${process.env.REACT_APP_ROOT_PATH}/`}
                    >
                        Return to homepage
                    </SdfLink>
                    <SdfLink
                        target='_blank'
                        emphasis="secondary"
                        href={`${process.env.REACT_APP_ROOT_PATH}/support/help-center`}
                        className="pt-3 md:pt-0"
                    >
                        Contact us
                    </SdfLink>
                </div>
            </div>
            <div className="w-max md:w-screen">
                <img
                    className="w-full md:mt-16 mb-6 md:w-10/12 md:ml-32"
                    src={Icon}
                    alt='Not found'
                />
            </div>
        </div>
    )
}