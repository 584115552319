import React from 'react';
import ArticlesListWithFilter from "../../../components/articles/ArticlesListWithFilter";
import {FILTERS} from "../../../components/articles/filters";
import {InputWidthEnum} from "../../../utility/enums/InputWidthEnum";

const breadcrumbPath = [
    {name: 'Home', route: '/'},
    {name: 'Guides / ADP Marketplace Integration Guides'},
]

const INPUT_WIDTH_MD = InputWidthEnum.FULL

function MarketplaceIntegrationGuidesPage() {
     return (
         <ArticlesListWithFilter
            pageTitle="ADP Marketplace Integration Guides"
            navigatePath="/guides/adp-marketplace-integration-guides"
            breadcrumbPath={breadcrumbPath}
            fieldsDescriptions={['GUIDE TITLE/DESCRIPTION', 'LAST UPDATED']}
            filtersDefinition={[
                FILTERS.input,
                { ...FILTERS.browseBySolution, inputWidthMd: INPUT_WIDTH_MD},
                { ...FILTERS.sortBy, inputWidthMd: INPUT_WIDTH_MD},
            ]}
            description={{
                illustrationName: 'dashboard',
                text: 'Use these guides to learn how to integrate with the Marketplace storefront and build inside the ADP API ecosystem.'
            }}
            customArticlesQuery={{
                tags: ['guide'],
                index: "guides",
                guideType: "mkplIntegration"
            }}
        />
     )
}

export default MarketplaceIntegrationGuidesPage;
