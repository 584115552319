import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Swagger} from "../../../components/api-explorer/swagger/Swagger";
import {
    selectProduct,
    setSwaggerUrl,
    useFetchProductsByApiQuery,
    useFetchProductsQuery,
    useFetchSwaggerQuery,
    useUpdateMetricsMutation,
    usePostElasticSearchByIdMutation
} from "../../../store";
import ApiOutline from "../../../components/api-explorer/api-view/ApiOutline";
import {SdfAlert} from "@synerg/react-components";
import {openAccordion, scrollToElement} from "../../../utility/DOMUtils"
import {useLocation, useParams, useSearchParams, useNavigate} from "react-router-dom";
import {buildQueryObj} from "../../../utility/FetchingUtils";
import {swaggerApi} from "../../../store/apis";
import {getBaseUrl} from "../../../utility/transform/path";
import {NotFound} from "../../NotFound";
import {BusyPageIndicator} from "../../../components/BusyPageIndicator";
import Breadcrumb from "../../../components/Breadcrumb";
import { getDomainLabel } from "../../../utility/apiExplorerUtils";


function ApiExplorerApiView() {
    const {pathname, search} = useLocation();
    const {apiCode, productCode} = useParams()
    const [searchParams] = useSearchParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [swaggerData, setSwaggerData] = useState() 

    const {
        data: productsData,
        isLoading: productsIsLoading,
        isSuccess: productsIsSuccess
    } = useFetchProductsQuery();

    const[postElasticSearchById, {
        data: apiData,
        isSuccess: apiDataIsSuccess,
        isLoading: apiDataIsLoading
    } ]= usePostElasticSearchByIdMutation();

    useEffect(()=>{
        postElasticSearchById(buildQueryObj({index: 'api', id: apiCode}));
    }, [apiCode])

    function getApiExplorerApi() {

        if (apiDataIsSuccess) {
            const {_source, ...rest} = apiData?.hits?.hits[0] || {};

            return {..._source, ...rest}
        }

        return {resourcePath: ""}
    }

    function getApiExplorerProduct() {
        return productsData?.find(product => product.key === productCode);
    }

    const breadcrumbPath = [
        {name: 'Home', route: '/'},
        {name: 'APIs'},
        {name: 'API Explorer', route: '/apis/api-explorer'}
    ]

    const apiExplorerProduct = getApiExplorerProduct()
    const apiExplorerApi = getApiExplorerApi()

    if(apiExplorerProduct) {
        breadcrumbPath.push({
            name: apiExplorerProduct?.title || apiExplorerProduct?.name,
            route: `/apis/api-explorer/${apiExplorerProduct?.key}`
        })
    
        if(apiExplorerApi && apiExplorerApi.domain && apiExplorerApi.title) {
            breadcrumbPath.push({
                name: getDomainLabel(apiExplorerApi?.domain),
                route: `/apis/api-explorer/${apiExplorerProduct?.key}?domain=${apiExplorerApi?.domain}`
            })
            breadcrumbPath.push({name: apiExplorerApi?.title, route: ''})
        }
    }

    const resourcePath = getApiExplorerApi()?.resourcePath;
    const {
        data: apiRelatedProducts,
        isSuccess: apiRelatedProductsIsSuccess
    } = useFetchProductsByApiQuery({resourcePath}, {skip : !resourcePath})

    function getApiRelatedProductsItems() {
        if (productsIsSuccess && apiRelatedProductsIsSuccess) {
            return apiRelatedProducts.map(apiRelatedProduct => {
                const productLabel = productsData.find(product => product.key === apiRelatedProduct.productCode)
                return {
                    label: productLabel.title,
                    value: productLabel.key
                }
            })
        }
    }

    useEffect(() => {
        //set head title dynamically with api title
        if(apiExplorerApi && apiExplorerApi.title) {
            document.title = apiExplorerApi.title
        }

        return () => {
            document.title = "ADP Developer Resources"
        }
    }, [apiExplorerApi])

    const [updateMetrics, results] = useUpdateMetricsMutation();

    useEffect(() => {
        updateMetrics({id: apiCode, index: "api"});
    }, []);

    const errorMsg = results.error ?
        <>
            <SdfAlert closeable status="error">An error occurred when updating metrics for this document</SdfAlert>
        </>
        : <></>

    const fetchSwagger = useFetchSwaggerQuery(
        {url: getApiExplorerApi()?.swagger},
        { skip: (!apiDataIsSuccess) }
    );

    useEffect(() => {
        setSwaggerData(fetchSwagger)
        dispatch(setSwaggerUrl(getBaseUrl(getApiExplorerApi()?.swagger)));

    }, [fetchSwagger])


    useEffect(() => {
        if (search) {
            if (swaggerData?.isSuccess && apiRelatedProductsIsSuccess && productsIsSuccess) {
                const operation = searchParams.get("operation")
                setTimeout(() => {
                    const element = document.getElementById(operation)
                    openAccordion(element)
                    scrollToElement(element)

                }, 300)
            }
        }
    }, [swaggerData?.isSuccess, apiRelatedProductsIsSuccess, productsIsSuccess])

    async function onHandleSelect(event) {
        const relatedProduct = apiRelatedProducts.find(product => product.productCode === event.detail.value);
        const selectedProduct = productsData.find(product => product.key === event.detail.value);
        const product = {...relatedProduct, ...selectedProduct};        
        const resourcePath = getApiExplorerApi()?.resourcePath;
        const apiId = product.key+'-'+resourcePath?.replace(/\//g, '-');
        let updatedPathName = pathname.replace(productCode, product.key);
        updatedPathName = updatedPathName.replace(apiCode, apiId);
        const result = await dispatch(swaggerApi.endpoints.fetchSwagger.initiate({
            url: product.swagger,
            forceRefetch: true
        }))
        setSwaggerData(result);
        dispatch(selectProduct(product))
        dispatch(setSwaggerUrl(getBaseUrl(product.swagger)));
        navigate(updatedPathName)
    }

    if (swaggerData && !(swaggerData?.isUninitialized || swaggerData?.isLoading || swaggerData?.data))
        return <NotFound/>

    return (
        <div className="xl:px-12 md:px-10 sm:px-8 px-4">
            <Breadcrumb path={breadcrumbPath}/>
            {errorMsg}

            {(productsIsLoading || apiDataIsLoading || swaggerData?.isLoading) ?
                <BusyPageIndicator positionClass="absolute-center"/> :
                (
                    <>
                        {
                            swaggerData?.isSuccess && (
                                <>
                                    <ApiOutline
                                        api={getApiExplorerApi()}
                                        product={getApiExplorerProduct()}
                                        events={swaggerData?.data?.["x-adpSpecData"]?.eventNotifications}
                                        codelists={swaggerData?.data?.["x-adpSpecData"]?.codelistExamples}
                                        postmanCollection={swaggerData?.data?.["x-adpSpecData"]?.testCaseCollection}
                                        operations={{paths: swaggerData.data.paths, tags: swaggerData.data.tags}}
                                        productSelectItems={getApiRelatedProductsItems()}
                                        productSelectValue={{label: getApiExplorerProduct()?.title, value: getApiExplorerProduct()?.key}}
                                        productSelectHandler={onHandleSelect}
                                    />
                                    <Swagger paths={swaggerData.data.paths} tags={swaggerData.data.tags}/>
                                </>
                            )
                        }
                    </>
                )
            }
        </div>
    )
}

export default ApiExplorerApiView;
