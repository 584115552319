import { SdfButton } from "@synerg/react-components";
import React, { useEffect, useState } from "react";
import "./style.css";
import "../ClientIntegration/Header.css";
import { imageProps } from "./utility/constants";
import HeroImage from "../shared/HeroImage";
import { useNavigate } from "react-router-dom";
import { HeroImageList } from "../shared/HeroImageList";

export function MarketplaceSection() {
  const [showComponent, setShowComponent] = useState(window.innerWidth >= 640);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      if (currentWidth <= 640) {
        setShowComponent(false);
      } else {
        setShowComponent(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navigate = useNavigate();
  return (
    <div className='w-11/12 md:w-4/5'>
      <div className='flex flex-col xl:flex-row gap-8'>
        <div className='justify-between mt-12 xl:w-3/6'>
          <h1 className='font-black mb-12 text-heading-08 text-left'>
            Interested in becoming an ADP Marketplace partner?
          </h1>
          <p className='sm:w-xl sm:text-heading-04 text-left'>
            Learn about the application process, explore ways to integrate your
            solution with ADP, and more.
          </p>
        </div>
        {showComponent && (
          <div className='xl:mr-20 flex-shrink-0 xl:w-xl xl:py-0 h-xs mb-10 w-full'>
            <HeroImage {...imageProps} />{" "}
          </div>
        )}

        {!showComponent && <HeroImageList {...imageProps} />}
      </div>

      <div className='bg-action-primary-900 rounded-lg text-inverse-default mt-32 px-16 xl:px-32 py-8 shadow-8'>
        <div className='flex flex-col xl:flex-row gap-4'>
          <div className='flex flex-col xl:w-2xl'>
            <div className='text-brand-secondary text-heading-01 font-bold mt-4 uppercase'>
              Api inventory
            </div>
            <div className='text-heading-06 font-bold mb-4'>
              Explore APIs by ADP product
            </div>
            <p className='text-body-03 mb-8'>
              View code samples of requests and responses from APIs for ADP
              products including ADP Workforce Now®, ADP Vantage HCM®, and more.
            </p>
          </div>
          <div className='flex flex-shrink-0'>
            <SdfButton
              emphasis='primary'
              className='w-sm my-20'
              size='lg'
              onClick={() => navigate("/apis/api-explorer")}
            >
              Browse APIs
            </SdfButton>
          </div>
        </div>
      </div>
    </div>
  );
}
