import { useState, useEffect } from "react";
import { SdfPromoBox, SdfButton, SdfFocusPane } from "@synerg/react-components";

import { copyToClipboard } from "../../utility/copyToClipboard";

const wfnUrl = "https://apps.adp.com/en-US/apps/410612?cro=t2435";
const otherUrl = "https://apps.adp.com/en-US/apps/419897?cro=t2435";

const boxProps = {
  header: "Recommend API Central to your HR team",
  message:
    "An HR practitioner must activate API Central on your organization’s behalf. Share the app listing page to help them get started.",
  illustrationName: "info",
  layout: "horizontal",
};

const getCopyButtonText = (url, copyUrl) =>
  url === copyUrl ? "Copied" : "Copy link";

const RecommendModal = ({ visible, closeModal }) => {
  const [copyUrl, setCopyUrl] = useState(null);

  useEffect(() => {
    setCopyUrl(null);
  }, [visible]);

  const handleCopy = (url) => {
    copyToClipboard(url);
    setCopyUrl(url);
  };

  const focusPaneProps = {
    closeable: true,
    dismissOnClickOutside: true,
    hideFooter: true,
    onSdfDismiss: closeModal,
    visible,
  };

  const wfnCopyProps = {
    emphasis: "primary",
    onClick: () => handleCopy(wfnUrl),
  };
  const otherCopyProps = {
    emphasis: "primary",
    onClick: () => handleCopy(otherUrl),
  };

  return (
    <SdfFocusPane {...focusPaneProps}>
      <div className='px-12 pb-12 flex flex-col gap-4'>
        <h1 className='m-0 text-heading-06 text-center'>
          Recommend API Central to your HR teammate
        </h1>
        <p className='m-0 pb-8 text-body-02 text-center'>
          Share the relevant link below with your HR team so they can activate
          the solution for you on ADP Marketplace.
        </p>
        <div className='p-8 bg-action-default-50 flex flex-col gap-8'>
          <div className='m-0 text-heading-04 text-center'>
            Send to an HR practitioner at your company so they can learn more.
          </div>
          <div className='flex'>
            <div className='flex-grow'>
              <div className='m-0 text-heading-02'>
                API Central for ADP Workforce Now®
              </div>
              <div className='m-0 text-body-02'>{wfnUrl}</div>
            </div>
            <SdfButton {...wfnCopyProps}>
              {getCopyButtonText(wfnUrl, copyUrl)}
            </SdfButton>
          </div>
          <div className='flex'>
            <div className='flex-grow'>
              <div className='m-0 text-heading-02'>
                API Central for other ADP platforms
              </div>
              <div className='m-0 text-body-02'>{otherUrl}</div>
            </div>
            <SdfButton {...otherCopyProps}>
              {getCopyButtonText(otherUrl, copyUrl)}
            </SdfButton>
          </div>
        </div>
      </div>
    </SdfFocusPane>
  );
};

const RecommendApiCentral = () => {
  const [visible, setVisible] = useState(false);

  const buttonProps = {
    onClick: () => setVisible(true),
    slot: "cta",
  };

  const modalProps = {
    closeModal: () => setVisible(false),
    visible,
  };

  return (
    <>
      <div className='w-11/12 md:w-4/5'>
        <SdfPromoBox {...boxProps}>
          <SdfButton {...buttonProps}>Recommend</SdfButton>
        </SdfPromoBox>
      </div>
      <RecommendModal {...modalProps} />
    </>
  );
};

export default RecommendApiCentral;
