/* eslint-disable no-restricted-globals */
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {InputWidthEnum} from "../../utility/enums/InputWidthEnum";
import {useFetchArticlesQuery} from "../../store";
import {buildQueryObj} from "../../utility/FetchingUtils";
import {generateFiltersAndQueryArticles} from "../articles/generateFiltersAndQueryArticles";
import {FILTERS, getFiltersForUrlParams} from "../articles/filters";
import {FilterTypesEnum} from "../../utility/enums/FilterTypesEnum";
import {ArticlesFilterQueriesEnum} from "../../utility/enums/ArticlesFilterQueriesEnum";
import {urlParamsUtil} from "../../utility/useEffect/urlParamsUtil";
import ApiExplorerProductListView from "./ApiExplorerProductListView";
import ApiExplorerProductGridView from "./ApiExplorerProductGridView";
import {NoData} from "../NoData";
import {localFilterUseEffect} from "../../utility/useEffect/localFilterUtil";
import {ALL_SELECT_ITEM} from "../../utility/constants";
import {handleLocalSingleSelect} from "../../utility/handlers/formHandler";
import {isFetching} from "../../utility/articlesUtils";
import {BusyPageIndicator} from "../BusyPageIndicator";
import ApiExplorerHeader from "./ApiExplorerHeader";

const INPUT_WIDTH_MD = InputWidthEnum.FULL
const DOMAIN_KEY = 'domain'

export default function ApiExplorerProductViewWithFilter({
                                                             apiExplorerProductView,
                                                             productsSelect,
                                                             selectedProduct
                                                         }) {

    const adpNaProducts = productsSelect.filter(product => product.offerType === 'northAmerica')
    const adpIntProducts = productsSelect.filter(product => product.offerType === 'international')

    const [domainsSelect, setDomainsSelect] = useState([ALL_SELECT_ITEM])
    const [apis, setApis] = useState([])
    const navigate = useNavigate()
    const {
        filters,
        articlesQuery,
        searchParams,
        setSearchParams
    } = generateFiltersAndQueryArticles({
            filtersDefinition: [
                { ...FILTERS.input, id: "apiSearch" },
                {
                    id: 'northAmericaFilter',
                    filterType: FilterTypesEnum.SelectSimple,
                    initialValue: selectedProduct?.offerType === 'northAmerica' ? selectedProduct : null,
                    queryKey: ArticlesFilterQueriesEnum.API_PRODUCT_CODE,
                    selectItems: adpNaProducts,
                    customEventHandlerAfter: (e) => {
                        const product = e?.target?.value;
                        if (!product) return;
                        const [, index, type, ,] = location.pathname.split('/');
                        navigate(`/${index}/${type}/${product.value}${location.search}`);
                    },
                    label: 'North America',
                    inputWidthMd: INPUT_WIDTH_MD,
                    isUrlParam: false,
                    noTag: true
                },
                {
                    id: 'internationalFilter',
                    filterType: FilterTypesEnum.SelectSimple,
                    initialValue: selectedProduct?.offerType === 'international' ? selectedProduct : null,
                    queryKey: ArticlesFilterQueriesEnum.API_PRODUCT_CODE,
                    selectItems: adpIntProducts,
                    customEventHandlerAfter: (e) => {
                        const product = e?.target?.value;
                        if (!product) return;
                        const [, index, type, ,] = location.pathname.split('/');
                        navigate(`/${index}/${type}/${product.value}${location.search}`);
                    },
                    label: 'International',
                    inputWidthMd: INPUT_WIDTH_MD,
                    isUrlParam: false,
                    noTag: true,

                },
                {
                    id: 'domainFilter',
                    filterType: FilterTypesEnum.SelectSimple,
                    initialValue: ALL_SELECT_ITEM,
                    selectItems: domainsSelect,
                    customEventHandlerAfter: (e) => handleLocalSingleSelect(apisFromQuery, setApis)(e),
                    customUrlKey: DOMAIN_KEY,
                    label: 'Filter by domain',
                    inputWidthMd: INPUT_WIDTH_MD,
                    noTagIfValue: ALL_SELECT_ITEM.value
                },
                { ...FILTERS.sortBy, id: "apiSort", inputWidthMd: INPUT_WIDTH_MD},
            ],
            filterFunctionName: "findQueryFilterForApiProductsCode"
        }
    );


    useEffect(() => {
        if (selectedProduct?.offerType === "northAmerica") {
            filters[2].setSelectSimple(null)
        }

        if (selectedProduct?.offerType === "international") {
            filters[1].setSelectSimple(null)
        }
    }, [filters])


    useEffect(() => {
        urlParamsUtil(
            searchParams,
            getFiltersForUrlParams(filters)
        )
    }, [])

    const apisQuery = useFetchArticlesQuery(buildQueryObj({
        ...articlesQuery,
        index: "api",
        tags: ["apiexplorer"],
        _source: [
            '_id',
            'title',
            'modifiedAt',
            'domain',
            'content',
            'productCode'
        ]
    }))

    const apisFromQuery = apisQuery?.data?.articles;

    useEffect(() => {
        localFilterUseEffect(
            apisQuery,
            filters,
            DOMAIN_KEY,
            setApis,
            setDomainsSelect,
            apisFromQuery,
            DOMAIN_KEY,
            searchParams,
            setSearchParams
        )
    }, [apisFromQuery])

    const loadedContentUi = () => {
        if (apisQuery.isLoading) return
        if (!apisFromQuery?.length) return <NoData withBorderTop withBorderBottom/>

        return (
            apiExplorerProductView === 1 ? (
                apisQuery.isSuccess && <ApiExplorerProductListView products={apis}/>
            ) : (
                apisQuery.isSuccess && <ApiExplorerProductGridView products={apis}/>
            )
        )
    }


    return (
        <>
            <ApiExplorerHeader filters={filters} count={apisFromQuery?.length} />
            {
                isFetching(apisQuery) ?
                <BusyPageIndicator positionClass="list-loading"/> :
                loadedContentUi()
            }
        </>
    )
}