import React from 'react';
import {SdfBox, SdfIcon, SdfLink} from "@synerg/react-components";
import {generateUrl} from "../utility/UriUtil";

const onHandleSignIn = () => {
    window.location.href = generateUrl(`${process.env.REACT_APP_API_PATH}/authenticate?redirUrl=${window.location.href}`);
}

function ShouldLoginBox({text='Sign in to view implementation-specific content and find the answers you need.'}) {
    return (
        <SdfBox className="flex items-center justify-between w-full">
            <div className="md:ml-2">
                <SdfIcon className="w-0 md:w-auto" icon="alert-info" />
                <span className="md:ml-2 mr-2">
                    {text}
                </span>
            </div>
            <SdfLink
                className="ml-3 whitespace-pre"
                size="sm"
                onClick={onHandleSignIn}
                emphasis="secondary"
                external={true}
                href="#">
                Sign in
            </SdfLink>
        </SdfBox>
    )
}

export {ShouldLoginBox}
