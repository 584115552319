const guideItemList = [
  {
    header: "Human resources",
    message:
      "Sensitive and non-sensitive personal data, demographic, employment and pay-related data",
    pathName: "/guides/api-guides?useCaseTags=Human-Resources",
  },
  {
    header: "Time & labor management",
    message:
      "Time and attendance, workforce management,  timeclock punches, shift scheduling",
    pathName: "/guides/api-guides?useCaseTags=Time-Labor-Management",
  },
  {
    header: "Payroll management",
    message:
      "Data needed for payroll processing, such as regular hours and overtime hours, as well as processed data, such as earnings, deductions, taxes and more",
    pathName: "/guides/api-guides?useCaseTags=Payroll-Management",
  },
  {
    header: "Recruiting",
    message:
      "Data related to job requisitions, applications and assessments, background checks, hiring, onboarding",
    pathName: "/guides/api-guides?useCaseTags=Recruiting",
  },
  {
    header: "Benefits management",
    message:
      "Employee benefits, deductions, dependents, and insurance management",
    pathName: "/guides/api-guides?useCaseTags=Benefit-Management",
  },
];

export { guideItemList };