import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { SdfPromoBox } from "@synerg/react-components";
import Timeline from "../shared/Timeline";

  export const GoLiveReadiness = () => {
    return (
      <>
        <p className='text-center bg-action-default-100 mb-0 pt-4'>
          Go-live readiness
        </p>
        <div className='flex justify-between px-4 bg-action-default-100'>
          <ul className='font-normal'>
            <li>App listing content and pricing configuration</li>
            <li>Listing terms of service</li>
          </ul>
          <ul className='font-normal'>
            <li>Finance and billing</li>
            <li>Operational readiness</li>
          </ul>
        </div>
      </>
    );
  };

const IntegrationSteps = ({ timeline2Items }) => {
  const componentRef = useRef(null);
  const [showComponent, setShowComponent] = useState(false);

  const breakpoint = 640;
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      setShowComponent(width > breakpoint);
    });

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }
    return () => observer.disconnect();
  }, [componentRef.current]);

  return (
    <div
      className='flex flex-col items-center overflow-hidden'
      ref={componentRef}
    >
      {showComponent && (
        <div className='xl:w-11/12 m-auto'>
          <>
            <div className='grid grid-cols-5'>
              {/* Top Row: Icons and Titles */}
              {timeline2Items.map((item, index) => (
                <div
                  key={index + timeline2Items.length}
                  className='grid-item first-row'
                >
                  <SdfPromoBox
                    header={item.header}
                    illustration-name={item.illustrationName}
                    align='center'
                    layout='vertical'
                    size='sm'
                    variant='clear'
                  ></SdfPromoBox>
                </div>
              ))}
              {/* Horizontal Timeline Line with Dots */}
              <div className='relative col-span-5'>
                <div className='timeline-line'></div>
                <div className='flex justify-between w-full'>
                  {timeline2Items.map((item, index) => (
                    <div
                      key={index + timeline2Items.length}
                      className='timeline-marker-wrapper'
                    >
                      {" "}
                      <div className='timeline-marker'></div>
                    </div>
                  ))}
                </div>
              </div>
              {/* Bottom Row: Task Lists */}
              {timeline2Items.map((item, index) => (
                <div
                  key={index + timeline2Items.length}
                  className='grid-item second-row'
                >
                  <ul className='text-left p-4 mt-0 mb-8 space-y-2 custom-weight text-md'>
                    {item.message.map((task, taskIndex) => (
                      <li
                        key={taskIndex + index}
                        className={`${
                          item.header === "Integrations" && taskIndex > 0
                            ? "list-disc ml-2"
                            : "list-none pb-2"
                        }`}
                      >
                        {task}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}{" "}
              <div className='col-span-3 col-start-3 go-live-readiness-section'>
                <h2 title='none' className='header'>
                  heading
                </h2>
                <GoLiveReadiness />
              </div>
            </div>
          </>
        </div>
      )}

      {!showComponent && (
        <>
          <Timeline
            {...{
              timelineItems: timeline2Items,
              hideDetails: true,
              homePageTimeline: true,
            }}
          />
          <div className="my-0 go-live-readiness-section ">
            <GoLiveReadiness />
          </div>
        </>
      )}
    </div>
  );
};

export default IntegrationSteps;
