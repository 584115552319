import {findProductFromId} from "../articlesUtils";

function articlesApiTransformByType(article) {

    if (article?._index === "general" && article?._source?.tags?.includes('howto')) {
        return {
            ...articlesApiTransform(article),
            tagName: 'HOWTO',
            navigationPath: `/getting-started/key-concepts/${article._id}`,
            isLearnArticle: true
        };
    }

    if (article?._index === "general" && article?._source?.tags?.includes('keyconcept')) {
        return {
            ...articlesApiTransform(article),
            tagName: 'KEY CONCEPT',
            navigationPath: `/getting-started/key-concepts/${article._id}`,
            isLearnArticle: true
        };
    }

    if (article?._index === "guides" && article?._source?.guideType === "productIntegration") {
        return {
            ...articlesApiTransform(article),
            isGuide: true,
            chapters: article?._source?.navigation,
            chaptersSections: article?._source?.sections,
            pdfLink: article?._source?.pdfLink,
            tagName: 'API GUIDE',
            navigationPath: `/guides/api-guides/${article._id}`
        };

    }

    if (article?._index === "guides" && article?._source?.guideType === "mkplIntegration") {
        return {
            ...articlesApiTransform(article),
            isGuide: true,
            chapters: article?._source?.navigation,
            chaptersSections: article?._source?.sections,
            pdfLink: article?._source?.pdfLink,
            tagName: 'ADP MARKETPLACE INTEGRATION GUIDE',
            navigationPath: `/guides/adp-marketplace-integration-guides/${article._id}`
        };
    }

    if (article?._index === "api") {
        return {
            ...articlesApiTransform(article),
            tagName: 'API',
            navigationPath: `/apis/api-explorer/${article?._source?.productCode}/${article?._id}`,
            abstract: article._source?.content,
            isPublic: true,
            productName: findProductFromId(article._source?.productCode)?.label
        };
    }

    if (article?._index === "usecases") {
        return {
            ...articlesApiTransform(article),
            tagName: 'USECASE',
            navigationPath: `/solutions/usecases/${article._id}`
        };
    }

    return {...articlesApiTransform(article)}
}

function articlesApiTransform(article) {
    return {
        title: article._source?.title || article.fields?.title[0],
        abstract: article._source?.abstract || article._source?.subtitle,
        isPublic: isPublicArticle(article),
        // date: transformDateToLongWithComma(new Date(article._source?.modifiedAt)),
        ...article
    }
}

function articlesPreviewApiTransform(article) {
    return {
        ...articlesApiTransform(article),
        isGuide: article?._source?.tags.includes('guide'),
        isChapter: !article?._source?.navigation?.length,
        content: article?._source?.content
    }
}

function isPublicArticle(article) {
    return article?._source?.rbac?.includes('public')
}

export {articlesApiTransform, isPublicArticle, articlesApiTransformByType, articlesPreviewApiTransform}