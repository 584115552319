import { SdfBusyIndicator } from "@synerg/react-components";

import Header from "./Header";
import FeaturedSolutions from "./FeaturedSolutions";
import AdditionalSolutions from "./AdditionalSolutions";

import { useFetchProductsQuery } from "../../store";

const basePath = "/apis/api-explorer/";

const FeaturedApis = () => {
  const { data, error, isLoading } = useFetchProductsQuery();

  const solutionList =
    data?.map(({ key, ...rest }) => ({
      pathName: `${basePath}${key}`,
      ...rest,
    })) || [];

  const childProps = { solutionList };

  return (
    <div className="flex flex-col items-center gap-4">
      <Header />
      {error && <h3>Error loading API Explorer products</h3>}
      {isLoading && <SdfBusyIndicator />}
      {!error && !isLoading && (
        <>
          <FeaturedSolutions {...childProps} />
          <AdditionalSolutions {...childProps} />
        </>
      )}
    </div>
  );
};

export default FeaturedApis;
