import { useNavigate } from "react-router-dom";
import { SdfButton } from "@synerg/react-components";

import sortAlphabeticalNoSymbol from "../../utility/sortAlphabeticalNoSymbol";
import "./FeaturedSolutions.css";

const defaultDescription =
  "Lorem ipsum odor amet, consectetuer adipiscing elit. Suspendisse in tempor dolor risus neque dignissim dignissim.";

/**
 * @typedef {Array<{
 *    title: string,
 *    description?: string,
 *    pathName: string,
 *    isPopular: boolean,
 *    internationalOffering: boolean,
 *    northAmericaOffering: boolean
 *  }>} solutionList
 * @param {{ gridTitle: string, list: solutionList }} props
 */
const SolutionGrid = ({ gridTitle, list }) => {
  const navigate = useNavigate();

  return (
    <div>
      <h3 className="m-0 text-heading-05">{gridTitle}</h3>
      <div className="grid grid-cols-1 auto-rows-fr gap-4 featured-solution-grid">
        {list.map(({ title, description, pathName }, index) => (
          <div
            key={`featured-solution-${gridTitle}-${index}`}
            className="p-4 relative flex flex-col gap-4"
          >
            <h4 className="m-0 text-heading-04">{title}</h4>
            <p className="m-0 text-body-02 flex-grow">
              {description || defaultDescription}
            </p>
            <SdfButton variant="text" onClick={() => navigate(pathName)}>
              View APIs
            </SdfButton>
          </div>
        ))}
      </div>
    </div>
  );
};

/**
 * @param {{ solutionList: solutionList }} props
 */
const FeaturedSolutions = ({ solutionList }) => {
  const northAmericaProps = {
    gridTitle: "North American Solutions",
    list: solutionList.filter(
      (solution) => solution.northAmericaOffering && solution.isPopular
    ),
  };

  const internationalProps = {
    gridTitle: "International Solutions",
    list: solutionList
      .filter(
        (solution) => solution.internationalOffering && solution.isPopular
      )
      .sort(sortAlphabeticalNoSymbol),
  };

  return (
    <div className="w-11/12 md:w-4/5">
      <h2 className="m-0 pt-4 pb-2 text-heading-06">
        Featured Solutions for Integration
      </h2>
      <div className="pt-4 grid grid-cols-1 lg:grid-cols-2 auto-rows-fr gap-8">
        <SolutionGrid {...northAmericaProps} />
        <SolutionGrid {...internationalProps} />
      </div>
    </div>
  );
};

export default FeaturedSolutions;
