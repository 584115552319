import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const waypointLoadDelay = 500;
const offset = 100;

const scrollToElement = (hash) => {
  const element = document.getElementById(hash.substring(1));
  if (element) {
    window.scrollTo({ top: element.offsetTop - offset, behavior: "smooth" });
  }
};

export default function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      scrollToElement(location.hash);
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => scrollToElement(location.hash), waypointLoadDelay);
    }
  }, []);

  return null;
}
