import React from "react";
import { UseCases } from "../components/welcome-page/UseCases";
import { HeroHeader } from "../components/welcome-page/HeroHeader";
import { AccessData } from "../components/welcome-page/AccessData";
import {
  AVAILABLE_APIs,
  INTEGRATION_OPTIONS,
  API_TITLE,
  USE_CASE_ROLES,
  FAQ_ITEMS,
} from "../components/welcome-page/utility/constants";
import { FAQs } from "../components/shared/FAQs";

function WelcomePage({ user }) {
  return (
    <div className='welcomePage'>
      <HeroHeader integrationOptions={INTEGRATION_OPTIONS} />
      <UseCases useCaseRoles={USE_CASE_ROLES} />
      <section className='bg-action-default-50'>
        <AccessData availableAPIs={AVAILABLE_APIs} tableTitles={API_TITLE} />
        <FAQs faqItems={FAQ_ITEMS} />
      </section>
    </div>
  );
}

export default WelcomePage;
