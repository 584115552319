import React from "react";
import { SdfExpandableBox } from "@synerg/react-components";

const FAQBox = ({ header, detail }) => {
  return (
    <SdfExpandableBox
      className="h-16 m-1 text-sm"
      header={header}
      triggerPlacement="after"
      variant="shadowed"
      spacing="tight"
    >
      <div
        className="mt-4 body-02 text-body-02 p-2"
      >
        {<p dangerouslySetInnerHTML={{ __html: detail }} />}
      </div>
    </SdfExpandableBox>
  );
};

export function FAQs({faqItems}) {
  return (
    <div className="flex flex-col m-auto py-6 max-w-3/4">
      <p className="text-heading-06 font-black text-center pb-4">
        Commonly asked questions
      </p>
      <section className="pb-12">
        {faqItems.map((item, idx) => (
          <FAQBox key={idx} {...item} />
        ))}
      </section>
    </div>
  );
}
