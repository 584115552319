import { useNavigate } from "react-router-dom";
import { SdfButton } from "@synerg/react-components";

import sortAlphabeticalNoSymbol from "../../utility/sortAlphabeticalNoSymbol";

/**
 * @typedef {Array<{
 *    title: string,
 *    pathName: string,
 *    isPopular: boolean,
 *    internationalOffering: boolean,
 *    northAmericaOffering: boolean
 *  }>} solutionList
 * @param {{ gridTitle: string, list: solutionList }} props
 */
const SolutionGrid = ({ gridTitle, list }) => {
  const navigate = useNavigate();

  return (
    <div>
      <h2 className="m-0 text-heading-04">{gridTitle}</h2>
      <div className="py-4 grid grid-cols-1 auto-rows-fr gap-4">
        {list.map(({ title, pathName }, index) => (
          <SdfButton
            key={`featured-solution-${gridTitle}-${index}`}
            variant="text"
            onClick={() => navigate(pathName)}
          >
            {title}
          </SdfButton>
        ))}
      </div>
    </div>
  );
};

/**
 * @param {{ solutionList: solutionList }} props
 */
const AdditionalSolutions = ({ solutionList }) => {
  const northAmericaProps = {
    gridTitle: "Additional Solutions for North America",
    list: solutionList
      .filter(
        (solution) => solution.northAmericaOffering && !solution.isPopular
      )
      .sort(sortAlphabeticalNoSymbol),
  };

  const internationalProps = {
    gridTitle: "Additional Solutions for International",
    list: solutionList
      .filter(
        (solution) => solution.internationalOffering && !solution.isPopular
      )
      .sort(sortAlphabeticalNoSymbol),
  };

  return (
    <div className="w-full pt-8 pb-24 flex flex-col items-center bg-action-default-50">
      <div className="w-11/12 md:w-4/5">
        <div className="grid grid-cols-1 lg:grid-cols-2 auto-rows-fr gap-8">
          <SolutionGrid {...northAmericaProps} />
          <SolutionGrid {...internationalProps} />
        </div>
      </div>
    </div>
  );
};

export default AdditionalSolutions;
