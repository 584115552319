import React from 'react';
import {Link} from "react-router-dom";
import {SdfIcon} from "@synerg/react-components";

function getPath(path) {
    const LINKSTYLE = 'text-action-primary no-underline pr-1'
    let ret = [];
    if (path.length) {
        for (let i = 0; i < path.length - 1; i++) {
            let item = path[i];

            if (item.route) {
                ret.push(<span key={i}>
                    <Link className={LINKSTYLE} to={item.route}>{item.name}</Link>
                    <span> / </span>
                </span>)
            } 
            else {
                ret.push(<span key={i}>
                    <span className="text-tertiary no-underline pr-1">{item.name}</span>
                    <span> / </span>
                </span>)
            }
        }
        ret.push(<span key='end' className="text-tertiary"> {path[path.length - 1].name}</span>)
    }
    return (
        <div>
            <SdfIcon className={LINKSTYLE} icon='nav-move-left'/>
            {ret}
        </div>);
}

function Breadcrumb({path}) {
    return (
        <div className={`py-6 font-medium`} id="breadcrumb">
            {getPath(path)}
        </div>
    )
}

export default Breadcrumb
