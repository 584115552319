import React, { useState, useEffect } from "react";
import "../welcome-page/style.css";
import "./style.css";
import { SdfButton, SdfIcon, SdfPromoBox } from "@synerg/react-components";
import { GoLiveReadiness } from "../welcome-page/IntegrationSteps";
import { TIMELINE_2_ITEMS } from "../welcome-page/utility/constants";
import TimelineCarouselPartner from "./TimelineCarouselPartner";

export const Header = () => {
  return (
    <div className='flex flex-col gap-4 w-11/12 md:w-4/5 py-16 mx-auto'>
      <div className='font-bold text-heading-06 text-center'>
        The path to becoming an ADP Marketplace partner
      </div>
      <div className='text-body-02 font-weight-450 text-center'>
        Interested in selling your solution on ADP Marketplace? Here’s what you
        can expect if you are accepted into our exclusive program.
      </div>
    </div>
  );
};

export function PartnerSection({ timeline }) {
  const [stage, setStage] = useState(0);
  const goNext = () => setStage(stage + 1);
  const goPrev = () => setStage(stage - 1);
  const [showComponent, setShowComponent] = useState(window.innerWidth >= 1180);
  const handleDotClick = (index) => {
    setStage(index);
  };
  
  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;      
      if (currentWidth < 1180) {
        setShowComponent(false);
      } else {
        setShowComponent(true);
      }
    };

    handleResize();
  
    window.addEventListener("resize", handleResize);
  
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  return (
    <div className="md:w-11/12 xl:w-4/5">
      <Header />
      {showComponent && (
        <div className='flex mx-auto bg-action-default-50 rounded-l-lg'>
          <div className='grid-container w-11/12 pl-4 pr-4 pb-4 pt-16'>
            <>
              <div className='grid grid-cols-5'>
                {timeline.map((item, index) => (
                  <div
                    key={index + timeline.length}
                    className='grid-item first-row'
                  >
                    <SdfPromoBox
                    className="cursor-pointer"
                      header={item.header}
                      illustration-name={item.illustrationName}
                      align='center'
                      layout='vertical'
                      size='sm'
                      variant='clear'
                      onClick={() => handleDotClick(index)}
                    ></SdfPromoBox>
                  </div>
                ))}

                <div className='relative col-span-5'>
                  <div className='timeline-line'></div>
                  <div className='flex justify-between w-full'>
                    {timeline.map((item, index) => (
                      <div key={index} className='timeline-marker-wrapper'>
                        <div
                          className={`cursor-pointer timeline-marker ${
                            stage === index
                              ? "selected-item"
                              : ""
                          }`}
                          onClick={() => handleDotClick(index)}
                          aria-label={`Go to ${item.title} phase`}
                        ></div>
                      </div>
                    ))}
                  </div>
                </div>

                {timeline.map((item, index) => (
                  <div
                    key={index + timeline.length}
                    className='grid-item second-row'
                  >
                    <ul className='text-left p-4 mt-0 mb-8 space-y-2 custom-weight text-md'>
                      {item.message.map((task, taskIndex) => (
                        <li
                          key={taskIndex + index}
                          className={`${
                            item.title === "Integrations" && taskIndex > 0
                              ? "list-disc ml-2"
                              : "list-none pb-2"
                          }`}
                        >
                          {task}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
                <div className='col-span-3 col-start-3 go-live-readiness-section'>
                  <h2 title='none' className='header'>
                    heading
                  </h2>
                  <GoLiveReadiness />
                </div>
              </div>
            </>
          </div>

          <div className='w-1/4 bg-action-default-200 p-8 rounded-r-lg flex flex-col'>
            <div className='text-heading-05'>{timeline[stage].header}</div>
            <div className='m-0 text-body-02 flex-grow whitespace-pre-wrap'>
              {timeline[stage].details
                .replace(/\.. (?=[A-Z])/g, ".\n\n")
                .split("\n\n")
                .map((paragraph, index) => (
                  <p key={index}>{paragraph.trim()}</p>
                ))}
            </div>

            <div className='flex gap-2'>
              {stage !== 0 ? (
                <SdfButton
                  variant='text'
                  color='primary'
                  action='standard'
                  size='sm'
                  className={ stage === timeline.length - 1 ? "highlight" : "" }
                  onClick={() => goPrev()}
                >
                  <SdfIcon icon='nav-move-left' />
                  Prev
                </SdfButton>
              ) : null}
              <div className='flex-grow' />
              {stage !== timeline.length - 1 ? (
                <SdfButton
                  variant='text'
                  color='primary'
                  action='standard'
                  size='sm'
                  className={ stage === 0 ? "highlight" : "" }
                  onClick={() => goNext()}
                >
                  Next
                  <SdfIcon icon='nav-move-right' />
                </SdfButton>
              ) : null}
            </div>
          </div>
        </div>
      )}
      {!showComponent && (
        <div className="w-4/5 mx-auto">
          <TimelineCarouselPartner timelineItems={TIMELINE_2_ITEMS} />
        </div>
      )}
    </div>
  );
}
